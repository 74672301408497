// import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   static values = {
//     linkTypes: Array,
//     linkTypesIcons: Object
//   }

//   changeLinkType(event) {
//     event.preventDefault()

//     let currentLinkType = event.currentTarget.dataset['linkType']
//     let currentLinkTypeIndex = this.linkTypesValue.indexOf(currentLinkType)
//     // If the current link type is the last one, go back to the first one
//     if (currentLinkTypeIndex == this.linkTypesValue.length - 1) {
//       currentLinkTypeIndex = -1
//     }
//     let nextLinkTypeIndex = currentLinkTypeIndex + 1
//     let nextLinkType = this.linkTypesValue[nextLinkTypeIndex]

//     event.currentTarget.querySelector(".link-type-icon").innerHTML = this.linkTypesIconsValue[nextLinkType].icon
//     event.currentTarget.querySelector(".link-type-text").innerHTML = this.linkTypesIconsValue[nextLinkType].text
//     event.currentTarget.querySelector(".input-link-type").value = nextLinkType
//     event.currentTarget.dataset['linkType'] = nextLinkType

//     var spelements
//     var filteredSpelements
//     switch (nextLinkType) {
//       case 'no_rule':
//         spelements = event.currentTarget.parentElement.querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.dataset['ancestry'] + '"]')
//         filteredSpelements = Array.from(spelements).filter(element => element != event.currentTarget)
//         filteredSpelements.forEach(function (element) {
//           element.querySelector('.link-type-icon').innerHTML = this.linkTypesIconsValue['no_access'].icon
//           element.querySelector(".link-type-text").innerHTML = this.linkTypesIconsValue['no_access'].text
//           element.querySelector(".input-link-type").value = ''
//           element.dataset['linkType'] = ''
//         }, this)
//         break;
//       case 'no_access':
//         spelements = event.currentTarget.parentElement.querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.dataset['ancestry'] + '"]')
//         filteredSpelements = Array.from(spelements).filter(element => element != event.currentTarget)
//         filteredSpelements.forEach(function (element) {
//           element.querySelector('.link-type-icon').innerHTML = this.linkTypesIconsValue['no_access'].icon
//           element.querySelector(".link-type-text").innerHTML = this.linkTypesIconsValue['no_access'].text
//           element.querySelector(".input-link-type").value = ''
//           element.dataset['linkType'] = ''
//         }, this)
//         break;
//       case 'single':
//         spelements = event.currentTarget.parentElement.querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.dataset['ancestry'] + '"]')
//         filteredSpelements = Array.from(spelements).filter(element => element != event.currentTarget)
//         filteredSpelements.forEach(function (element) {
//           element.querySelector('.link-type-icon').innerHTML = this.linkTypesIconsValue['no_access'].icon
//           element.querySelector(".link-type-text").innerHTML = this.linkTypesIconsValue['no_access'].text
//           element.querySelector(".input-link-type").value = ''
//           element.dataset['linkType'] = ''
//         }, this)
//         break;
//       case 'children':
//         spelements = event.currentTarget.parentElement.querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.dataset['ancestry'] + '"][data-depth="' + (parseInt(event.currentTarget.dataset['depth']) + 1) + '"]')
//         filteredSpelements = Array.from(spelements).filter(element => element != event.currentTarget);
//         filteredSpelements.forEach(function (element) {
//           element.querySelector('.link-type-icon').innerHTML = this.linkTypesIconsValue['single'].icon
//           element.querySelector(".link-type-text").innerHTML = this.linkTypesIconsValue['single'].text
//           element.querySelector(".input-link-type").value = ''
//           element.dataset['linkType'] = ''
//         }, this)
//         break;
//       case 'descendants':
//         spelements = event.currentTarget.parentElement.querySelectorAll('.multisitable-company[data-ancestry^="' + event.currentTarget.dataset['ancestry'] + '"]')
//         filteredSpelements = Array.from(spelements).filter(element => element != event.currentTarget);
//         filteredSpelements.forEach(function (element) {
//           element.querySelector('.link-type-icon').innerHTML = this.linkTypesIconsValue['single'].icon
//           element.querySelector(".link-type-text").innerHTML = this.linkTypesIconsValue['single'].text
//           element.querySelector(".input-link-type").value = ''
//           element.dataset['linkType'] = ''
//         }, this)
//         break;
//     }
//   }
// }
