import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdownList", "mainButton"]

  toggleDropdown(event) {
    event.preventDefault()
    this.dropdownListTarget.classList.toggle('show')
  }

  selectOption(event) {
    event.preventDefault()
    const selectedText = event.currentTarget.textContent.trim()
    this.mainButtonTarget.textContent = selectedText
    this.dropdownListTarget.classList.remove('show')
  }
}
