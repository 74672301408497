import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["a"]

  connect() {
    this.addEventListeners()
  }

  disconnect() {
    this.removeEventListeners()
  }

  addEventListeners() {
    this.aTargets.forEach(link => {
      link.addEventListener('click', this.handleLinkClick.bind(this))
    })
  }

  removeEventListeners() {
    this.aTargets.forEach(link => {
      link.removeEventListener('click', this.handleLinkClick.bind(this))
    })
  }

  handleLinkClick(event) {
    this.aTargets.forEach(link => link.classList.remove('active'))
    event.currentTarget.classList.add('active')
    event.currentTarget.focus()
  }
}
